import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  navigationLink: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
    fontSize: 16,
    lineHeight: 2,
    textDecoration: 'none',
  },
  iconButton: {
    color: theme.palette.text.primaryContrast,
  },
  iconButton_color_black: {
    color: theme.palette.text.primary,
  },
  navigationLink_active_yes: {
    color: theme.palette.text.accent,
    cursor: 'default',
  },
}));

export default useStyles;
