import React, { useContext } from 'react';
import classNames from 'classnames';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import { NavigationFooter } from '../Navigation';
import Social from '../Social';
import Download from '../Download';

import theme from '../../../styles/theme';
import { navigationItems } from '../../../constants';
import { ThemeContext } from '../../../helpers/context';

import useStyles from './styles';

const Footer = () => {
  const classes = useStyles(theme);
  const isDarkTheme = useContext(ThemeContext) === 'dark';

  return (
    <div
      className={classNames(
        classes.footer,
        'qaFooter',
        isDarkTheme && classes.footer_theme_dark
      )}
    >
      <Container maxWidth={false}>
        <Box pt={[9, 9, 7]} pb={[7, 7, 5]} className={classes.footerWrapper}>
          <Box mb={5} className={classes.footerInner}>
            <div>
              <Box mb={7} pt={[0, 0, 3]} className={classes.logo}>
                {isDarkTheme ? (
                  <img src={'/images/logo/payday-logo.svg'} alt="PayDay" />
                ) : (
                  <img
                    src={'/images/logo/payday-logo-black.svg'}
                    alt="PayDay"
                  />
                )}
              </Box>
              <Box mb={5}>
                <NavigationFooter items={navigationItems} />
              </Box>
              <Box mb={[5, 5, 0]}>
                <a
                  href="mailto:support@payday.ru"
                  className={classes.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  support@payday.ru
                </a>
              </Box>
            </div>
            <div>
              <Box mb={[0, 0, 1]}>
                <Download />
              </Box>
              <Social />
            </div>
          </Box>
          <div>
            <Box mb={[3, 3, 0]} className={classes.legalInfo}>
              <Box mb={[7, 7, 0]} className={classes.documents}>
                <a
                  href="https://storage.payday.ru/document/confidentiality_app/latest.html"
                  className={classNames(classes.document, classes.link)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Политика конфиденциальности
                </a>
                <a
                  href="https://storage.payday.ru/document/agreement/latest.html"
                  className={classNames(classes.document, classes.link)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Пользовательское соглашение
                </a>
                <a
                  href="https://storage.payday.ru/document/confidentiality/latest.pdf"
                  className={classNames(classes.document, classes.link)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Соглашение об информационном взаимодействии
                </a>
              </Box>
              <Box className={classes.copyright}>
                <Box mb={1}>Не является публичной офертой</Box>
                &copy; 2021 VK
              </Box>
            </Box>
          </div>
        </Box>
      </Container>
    </div>
  );
};

export default Footer;
