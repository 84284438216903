import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ScrollUpButtonInner from './ScrollUpButtonInner';

import useStyles from './styles';
import theme from '../../../styles/theme';

const FullPageScrollUpButton = ({ handleClick, isVisible }) => {
  const classes = useStyles(theme);

  return (
    <div
      className={classNames(
        classes.fullpageScrollUp,
        isVisible && classes.fullpageScrollUp_visible
      )}
      id="fullpageScrollUp"
    >
      <ScrollUpButtonInner handleClick={handleClick} />
    </div>
  );
};

FullPageScrollUpButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  isVisible: PropTypes.bool,
};

FullPageScrollUpButton.defaultProps = {
  isVisible: false,
};

export default FullPageScrollUpButton;
