const styles = theme => ({
  navigation: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
    display: 'flex',
    transform: 'translateX(-8px)',
    flexWrap: 'wrap',
  },
  item: {
    position: 'relative',
  },
  link: {
    padding: '8px',
    display: 'inline-flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
    fontSize: 14,
    lineHeight: '32px',
    height: '32px',
    fontWeight: 600,
    borderRadius: '10px',
    textDecoration: 'none',
    marginRight: 32,
    transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      background: '#e0e0ea',
    },
  },
  navigation_theme_dark: {
    '& $link': {
      color: theme.palette.common.white,
      '&:hover': {
        background: '#38383D',
      },
    },
  },
  navigationLink_active_yes: {
    color: theme.palette.text.accent,
    '&:hover': {
      background: 'transparent !important',
    },
  },
});

export default styles;
