export const serializeQuery = obj => {
  const str = [];
  for (let key in obj) {
    str.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
  }
  return '?' + str.join('&');
};

export const queryToObject = query => {
  try {
    const a = decodeURIComponent(query.split('?')[1]);
    if (!a.trim().length) {
      return {};
    }

    const items = a.split('&');
    const res = items.reduce((accumulator, currentValue) => {
      const x = currentValue.split('=');
      return { ...accumulator, [x[0]]: x[1] };
    }, {});
    return res;
  } catch (e) {
    console.error(e);
  }
};
