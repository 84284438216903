import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { scroller } from 'react-scroll';
import { withRouter } from 'next/router';
import Link from 'next/link';
import classNames from 'classnames';

import ClosedIcon from '@material-ui/icons/ChevronRight';
import OpenedIcon from '@material-ui/icons/ExpandMore';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import NavigationButton from './NavigationButton/NavigationButton';

import handleScroll from '../../../../helpers/scrollToAnchor';
import { ThemeContext } from '../../../../helpers/context';
import styles from './styles';

const SCROLL_DURATION = 800;

const isUrlMatching = (path, href) => {
  if (href === '/') {
    return path === href;
  }
  return path.startsWith(href);
};

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static contextType = ThemeContext;

  handleOpen = href => e => {
    e.preventDefault();
    this.setState({ [href]: e.currentTarget });
  };

  handleClose = href => () => {
    this.setState({ [href]: null });
  };

  handleClick = e => {
    e.preventDefault();
    e.persist();

    const { router } = this.props;

    const { pathname, hash } = new URL(e.target.href);

    if (router.pathname === pathname) {
      if (hash) {
        scroller.scrollTo(hash.substr(1), {
          duration: SCROLL_DURATION,
          smooth: true,
        });
      }
    } else {
      router.push(pathname).then(() => {
        if (hash) {
          scroller.scrollTo(hash.substr(1), {
            duration: SCROLL_DURATION,
            smooth: true,
          });
        }
      });
    }
  };

  render() {
    const {
      level,
      items,
      classes,
      router,
      color,
      handleFullpageScroll,
    } = this.props;
    const isDarkTheme = this.context === 'dark';

    return (
      <>
        <Button
          color="primary"
          variant="contained"
          size="small"
          className={classes.button}
          onClick={() => {
            if (typeof handleFullpageScroll === 'function') {
              handleFullpageScroll();
            } else {
              handleScroll('form-anchor');
            }
          }}
        >
          Узнать подробнее
        </Button>
        <ul
          className={classNames(
            isDarkTheme && classes.navigation_theme_dark,
            level
              ? classNames(classes.subNavigation, 'qaSubNavigation')
              : classNames(classes.navigation, 'qaNavigationHeader')
          )}
        >
          {items.map(({ label, pathname, hash, children }) => {
            const path =
              pathname instanceof Function
                ? pathname(router.pathname)
                : pathname;
            const search = '';
            const href = path + (search || '') + (hash || '');

            return (
              <li
                className={classNames(classes.item, 'qaNavigationItem')}
                key={href}
                onMouseEnter={
                  children && children.length ? this.handleOpen(href) : () => {}
                }
                onMouseLeave={
                  children && children.length
                    ? this.handleClose(href)
                    : () => {}
                }
              >
                {!!children && !!children.length ? (
                  <Link href={href} passHref scroll={false}>
                    <NavigationButton
                      onCustomClick={this.handleClick}
                      color={color}
                      isUrlMatching={isUrlMatching(router.pathname, path)}
                      level={level}
                    >
                      {label}
                      {!!children &&
                        !!children.length &&
                        (this.state[href] ? (
                          <OpenedIcon className={classes.icon} />
                        ) : (
                          <ClosedIcon className={classes.icon} />
                        ))}
                    </NavigationButton>
                  </Link>
                ) : (
                  <Link href={href} passHref scroll={false}>
                    <NavigationButton
                      onCustomClick={this.handleClick}
                      color={color}
                      isUrlMatching={isUrlMatching(router.pathname, path)}
                      level={level}
                    >
                      {label}
                    </NavigationButton>
                  </Link>
                )}
                {!!children && !!children.length && (
                  <Popper
                    open={Boolean(this.state[href])}
                    anchorEl={this.state[href]}
                    role={undefined}
                    transition
                    disablePortal
                    placement="bottom-start"
                    className={classes.menu}
                  >
                    {({ TransitionProps }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin: 'center top',
                        }}
                      >
                        <Paper className={classes.menuPaper}>
                          <ClickAwayListener
                            onClickAway={this.handleClose(href)}
                          >
                            <Navigation
                              router={router}
                              classes={classes}
                              items={children}
                              level={level + 1}
                            />
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                )}
              </li>
            );
          })}
        </ul>
      </>
    );
  }
}

Navigation.propTypes = {
  items: PropTypes.array,
  level: PropTypes.number,
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  expanded: PropTypes.bool,
  color: PropTypes.oneOf(['white', 'black']),
  handleFullpageScroll: PropTypes.func,
};

Navigation.defaultProps = {
  items: null,
  level: 0,
  expanded: false,
  color: 'white',
  handleFullpageScroll: null,
};

export default withRouter(withStyles(styles)(Navigation));
