import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  social: {
    display: 'flex',
    margin: 0,
    padding: 0,
    justifyContent: 'flex-start',
    listStyleType: 'none',
    [theme.breakpoints.up('l')]: {
      justifyContent: 'flex-end',
    },
  },
  socialItem: {
    marginLeft: '16px',
    '&:first-child': {
      marginLeft: 0,
    },
  },
}));

export default useStyles;
