import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  button: {
    position: 'relative',
    background: theme.palette.common.white,
    '&:hover, &:active': {
      background: theme.palette.common.white,
    },
    width: '56px',
    height: '56px',
    border: '1px solid #E2E2EA',
    borderRadius: '16px',
    boxShadow:
      '0px 2px 8px rgba(0, 26, 77, 0.1), 0px 6px 28px rgba(0, 26, 77, 0.12)',
  },
  icon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  fullpageScrollUp: {
    position: 'fixed',
    left: '20px',
    bottom: '20px',
    zIndex: 10000,
    display: 'none',
    [theme.breakpoints.up('m')]: {
      display: 'block',
    },
    opacity: 0,
    transition: 'opacity 300ms ease',
  },
  fullpageScrollUp_visible: {
    opacity: 1,
  },
}));

export default useStyles;
