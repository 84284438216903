export const navigationItems = [
  {
    label: 'Работодателю',
    pathname: '/',
  },
  {
    label: 'Сотруднику',
    pathname: '/user',
  },
];
