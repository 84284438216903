import React from 'react';
import PropTypes from 'prop-types';
import ScrollToTop from 'react-scroll-up';

import ScrollUpButtonInner from './ScrollUpButtonInner';

import useStyles from './styles';
import theme from '../../../styles/theme';

const NormalPageScrollUpButton = ({ showUnder, bottom, right, left }) => {
  const classes = useStyles(theme);

  return (
    <ScrollToTop
      showUnder={showUnder}
      className={classes.scrollUpButton}
      style={{
        bottom,
        right,
        left,
        zIndex: 1001,
      }}
    >
      <ScrollUpButtonInner />
    </ScrollToTop>
  );
};

NormalPageScrollUpButton.propTypes = {
  showUnder: PropTypes.number,
  bottom: PropTypes.number,
  right: PropTypes.number,
  left: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

NormalPageScrollUpButton.defaultProps = {
  showUnder: 50,
  bottom: 20,
  right: 'auto',
  left: 20,
};

export default NormalPageScrollUpButton;
