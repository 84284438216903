import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import useStyles from './styles';
import theme from '../../../../../styles/theme';

const NavigationButton = React.forwardRef(
  ({ href, onCustomClick, color, isUrlMatching, children, level }, ref) => {
    const classes = useStyles(theme);

    return (
      <a
        href={href}
        onClick={onCustomClick}
        ref={ref}
        className={classNames(
          'qaNavigationLink',
          classes.navigationLink,
          !level && isUrlMatching && classes.navigationLink_active_yes,
          !!color && classes[`navigationLink_color_${color}`]
        )}
      >
        {children}
      </a>
    );
  }
);

NavigationButton.propTypes = {
  href: PropTypes.string,
  onCustomClick: PropTypes.func,
  color: PropTypes.oneOf(['white', 'black']),
  isUrlMatching: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  level: PropTypes.number,
};

NavigationButton.defaultProps = {
  onCustomClick: () => {},
  isUrlMatching: false,
  level: 0,
  href: '',
};

export default NavigationButton;
