import React from 'react';
import PropTypes from 'prop-types';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import theme from '../../../styles/theme';
import handleScroll from '../../../helpers/scrollToAnchor';

import NavigationMobile from './NavigationMobile';
import NavigationDesktop from './NavigationDesktop';

const Navigation = props => {
  const matches = useMediaQuery(theme.breakpoints.up('l'));
  const matchesScroll = useMediaQuery(theme.breakpoints.up('fullPageOn'));

  return matches ? (
    <NavigationDesktop {...props} />
  ) : (
    <NavigationMobile
      {...props}
      handleFullpageScroll={
        props.handleFullpageScroll &&
        (() => {
          if (
            matchesScroll &&
            typeof props.handleFullpageScroll === 'function'
          ) {
            props.handleFullpageScroll();
          } else {
            handleScroll('form-anchor');
          }
        })
      }
    />
  );
};

Navigation.propTypes = {
  handleFullpageScroll: PropTypes.func,
};

export default Navigation;
