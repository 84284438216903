import gql from 'graphql-tag';

const CMS_QUERY = gql`
  query Cms($start: Int) {
    banners {
      id
      title
      description
      imageS {
        url
      }
      imageM {
        url
      }
      imageL {
        url
      }
      landing
    }
    banners_client: banners(where: { landing: "client" }) {
      id
      title
      description
    }
    banners_user: banners(where: { landing: "user" }) {
      id
      title
      description
      landing
      imageS {
        url
      }
      imageM {
        url
      }
      imageL {
        url
      }
    }
    accomplishments(sort: "order:asc") {
      id
      title
      subtitle
      description
    }
    faqs_client: faqs(where: { landing: "client" }, sort: "order:asc") {
      id
      title
      description
    }
    products(sort: "order:asc") {
      id
      title
      description
    }
    innovations(sort: "order:asc") {
      description
    }
    steps_client: steps(where: { landing: "client" }, sort: "week:asc") {
      id
      description
      week
      landing
    }
    steps_user: steps(where: { landing: "user" }, sort: "week:asc") {
      id
      description
      week
      landing
    }
    paydays_client: paydays(where: { landing: "client" }, sort: "order:asc") {
      id
      description
      order
      landing
      icon {
        title
      }
    }
    paydays_user: paydays(where: { landing: "user" }, sort: "order:asc") {
      id
      description
      order
      landing
      icon {
        title
      }
    }
    advantages_client: advantages(
      where: { landing: "client" }
      sort: "order:asc"
    ) {
      id
      title
      description
    }
    advantages_user: advantages(where: { landing: "user" }, sort: "order:asc") {
      id
      title
      description
      order
      landing
      icon {
        title
      }
    }
    guarantees_client: guarantees(
      where: { landing: "client" }
      sort: "order:asc"
    ) {
      id
      description
      order
      landing
      icon {
        title
      }
    }
    guarantees_user: guarantees(where: { landing: "user" }, sort: "order:asc") {
      id
      description
      order
      landing
      icon {
        title
      }
    }
    reviews: reviews {
      id
      author
      title
      content
      rating
      avatar {
        url
      }
    }
    posts: posts(sort: "created_at:asc", start: $start, limit: 3) {
      id
      title
      content
      preview_img {
        url
      }
      preview_text
      published_at
    }
  }
`;

export default CMS_QUERY;
