import { makeStyles } from '@material-ui/core/styles';
const fontSize = 16;
const padding = 20;
const borderWidth = 3;

const useStyles = makeStyles(theme => ({
  navigation: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  link: {
    minWidth: '200px',
    textAlign: 'center',
    display: 'block',
    border: `${borderWidth}px solid transparent`,
    padding,
    color: theme.palette.app.navigationButtons.default,
    borderRadius: padding + fontSize / 2 + borderWidth,
    fontSize,
    lineHeight: 1,
    margin: '0 5px 10px',
    fontWeight: 600,
    textDecoration: 'none',
    '&:hover:not($link_active_yes)': {
      border: `${borderWidth}px solid ${theme.palette.app.navigationButtons.default}`,
    },
    transition: theme.transitions.create('border-color', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  link_active_yes: {
    border: `${borderWidth}px solid ${theme.palette.app.navigationButtons.active}`,
    '&:hover': {
      border: `${borderWidth}px solid ${theme.palette.app.navigationButtons.active}`,
      cursor: 'default',
    },
    color: theme.palette.app.navigationButtons.active,
  },
}));

export default useStyles;
