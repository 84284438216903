import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    position: 'relative',
    zIndex: 2,
    margin: '0 -20px',
    padding: '0 20px',
    [theme.breakpoints.up('m')]: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'nowrap',
      margin: '0 -40px',
      padding: '0 40px',
    },
  },
  inner: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '72px',
    flexDirection: 'row-reverse',
    [theme.breakpoints.up('m')]: {
      height: '96px',
    },
  },
  navigation: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row-reverse',
  },
  button: {
    marginLeft: 0,
  },
  logo: {
    height: 32,
    paddingLeft: '15px',
    borderLeft: '2px solid #AAAEB3',
    '&:first-child': {
      paddingRight: '15px',
      paddingLeft: 0,
      borderLeft: 'none',
    },
    [theme.breakpoints.up('l')]: {
      height: 48,
    },
  },
  logoVK: {
    height: '16px',
  },
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default useStyles;
