import React from 'react';

import theme from '../../../styles/theme';

import useStyles from './styles';

const Social = () => {
  const classes = useStyles(theme);

  return (
    <ul className={classes.social}>
      <li className={classes.socialItem}>
        <a
          href="https://www.linkedin.com/company/79352440/"
          className={classes.socialLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/images/social/in.svg" alt="Payday Linkedin" />
        </a>
      </li>
      <li className={classes.socialItem}>
        <a
          href="https://vk.com/payday"
          className={classes.socialLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/images/social/vk.svg" alt="Payday Vk" />
        </a>
      </li>
      <li className={classes.socialItem}>
        <a
          href="https://www.youtube.com/channel/UCA3xLG1kmhRWxHQ0YT60umg"
          className={classes.socialLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/images/social/youtube.svg" alt="Payday YouTube" />
        </a>
      </li>
      <li className={classes.socialItem}>
        <a
          href="https://ok.ru/group/57739912019977"
          className={classes.socialLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/images/social/ok.svg" alt="Payday Ok" />
        </a>
      </li>
    </ul>
  );
};

export default Social;
