const styles = theme => ({
  navigation: {
    zIndex: 1000,
    borderTopRightRadius: 13,
    borderBottomRightRadius: 13,
    listStyleType: 'none',
    position: 'relative',
    top: 0,
    padding: 0,
    display: 'flex',
    marginLeft: 20,
    alignItems: 'center',
    margin: 0,
  },
  subNavigation: {
    listStyleType: 'none',
    fontWeight: 400,
    fontSize: 12,
    margin: 0,
    minWidth: 250,
    padding: '15px 0',
    background: theme.palette.background.paper,
    borderRadius: '12px',
    border: `2px solid ${theme.palette.divider}`,
    '& $navigationLink': {
      color: theme.palette.text.primary,
    },
  },
  item: {
    position: 'relative',
  },
  navigationLink: {
    display: 'inline-flex',
    alignItems: 'center',
    color: theme.palette.text.primaryContrast,
    fontSize: 14,
    lineHeight: '32px',
    height: '32px',
    padding: '0 8px',
    fontWeight: 600,
    borderRadius: '10px',
    textDecoration: 'none',
    marginRight: 32,
    transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      background: 'rgba(255, 255, 255, .5)',
    },
  },
  navigationLink_color_black: {
    color: theme.palette.text.primary,
  },
  navigationLink_active_yes: {
    color: theme.palette.primary.main,
    cursor: 'default',
    '&:hover': {
      background: 'transparent',
    },
  },
  menu: {
    zIndex: 1000,
  },
  menuPaper: {
    borderRadius: '12px',
    background: 'none',
    boxShadow: 'none',
  },
  menuItem: {
    justifyContent: 'center',
    fontWeight: 600,
  },
  icon: {
    fontSize: 24,
    lineHeight: 28,
  },
  navigation_theme_dark: {
    '& $navigationLink': {
      '&:hover': {
        background: theme.palette.background.paper,
      },
    },
  },
});

export default styles;
