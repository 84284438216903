import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'next/router';

import { serializeQuery } from '../../helpers/transformQuery';
import { MetadataContext } from '../../helpers/context';

class Metadata extends Component {
  constructor(props) {
    super(props);
    this.queryParams = this.props.router.query;

    if (typeof window !== 'undefined') {
      if (Object.keys(this.queryParams).length) {
        localStorage.setItem('metadata', JSON.stringify(this.queryParams));
      }

      try {
        this.metadataString = serializeQuery(
          JSON.parse(localStorage.getItem('metadata'))
        );
      } catch (err) {
        console.error(err);
      }
    }
  }

  render() {
    const { children } = this.props;

    return (
      <MetadataContext.Provider value={this.metadataString}>
        {children}
      </MetadataContext.Provider>
    );
  }
}

Metadata.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  router: PropTypes.object.isRequired,
};

export default withRouter(Metadata);
