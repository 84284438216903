import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import IconButton from '@material-ui/core/IconButton';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import useStyles from './styles';
import theme from '../../../styles/theme';

const ScrollUpButtonInner = ({ handleClick }) => {
  const classes = useStyles(theme);

  return (
    <IconButton
      className={classNames(classes.button, 'qaArrowUp')}
      onClick={handleClick}
    >
      <ExpandLessIcon
        fontSize="medium"
        className={classes.icon}
        color="primary"
      />
    </IconButton>
  );
};

ScrollUpButtonInner.propTypes = {
  handleClick: PropTypes.func,
};

ScrollUpButtonInner.defaultProps = {
  handleClick: () => {},
};

export default ScrollUpButtonInner;
