import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import Link from 'next/link';
import classNames from 'classnames';
import { withRouter } from 'next/router';
import { withStyles } from '@material-ui/core/styles';

import { ThemeContext } from '../../../../helpers/context';
import styles from './styles';

const isUrlMatching = (path, href) => {
  if (href === '/') {
    return path === href;
  }
  return path.startsWith(href);
};

const NavigationFooter = ({ items, classes, router }) => {
  const isDarkTheme = useContext(ThemeContext) === 'dark';

  return (
    <ul
      className={classNames(
        classes.navigation,
        'qaNavigationFooter',
        isDarkTheme && classes.navigation_theme_dark
      )}
    >
      {items.map(({ pathname, hash, label }) => {
        const path =
          pathname instanceof Function ? pathname(router.pathname) : pathname;
        const search = '';
        const href = path + (search || '') + (hash || '');

        return (
          <li
            key={href}
            className={classNames(classes.item, 'qaNavigationItem')}
          >
            {isUrlMatching(router.pathname, path) ? (
              <span
                className={classNames(
                  classes.link,
                  'qaNavigationLink',
                  classes.navigationLink_active_yes
                )}
              >
                {label}
              </span>
            ) : (
              <Link href={href} passHref>
                <a className={classNames(classes.link, 'qaNavigationLink')}>
                  {label}
                </a>
              </Link>
            )}
          </li>
        );
      })}
    </ul>
  );
};

NavigationFooter.propTypes = {
  items: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(NavigationFooter));
