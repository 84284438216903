import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  footer: {
    background: theme.palette.background.dark,
  },
  footerInner: {
    fontWeight: '300',
    fontSize: '14px',
    lineHeight: 1,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('l')]: {
      flexDirection: 'row',
      textAlign: 'left',
      justifyContent: 'space-between',
    },
  },
  logo: {
    textAlign: 'left',
    '& img': {
      height: 48,
    },
  },
  link: {
    color: theme.palette.text.primary,
    fontWeight: 600,
  },
  contacts: {
    [theme.breakpoints.up('l')]: {
      minWidth: 350,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  },
  documents: {
    [theme.breakpoints.up('l')]: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
  document: {
    fontWeight: 'normal !important',
    display: 'block',
    marginBottom: '24px',
    [theme.breakpoints.up('l')]: {
      display: 'inline',
      marginLeft: '24px',
    },
  },
  legalInfo: {
    [theme.breakpoints.up('l')]: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row-reverse',
      fontSize: '13px',
    },
  },
  footerWrapper: {},
  copyright: {},
  footer_theme_dark: {
    '& $footerWrapper': {
      borderTop: `1px solid ${theme.palette.background.darkLight}`,
    },
    '& $link': {
      color: theme.palette.common.white,
    },
    '& $copyright': {
      color: '#96969e',
    },
  },
}));

export default useStyles;
