import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Navigation from '../Navigation';
import theme from '../../../styles/theme';
import { navigationItems } from '../../../constants';

import useStyles from './styles';

const Header = ({ color, handleFullpageScroll }) => {
  const classes = useStyles(theme);

  return (
    <div className={classes.header}>
      <div className={classes.inner}>
        <div className={classes.navigation}>
          <Navigation
            color={color}
            items={navigationItems}
            handleFullpageScroll={handleFullpageScroll}
          />
        </div>
        <div className={classes.logoWrapper}>
          <img
            src={
              color === 'white'
                ? '/images/logo/payday-logo.svg'
                : '/images/logo/payday-logo-black.svg'
            }
            alt="Payday"
            className={classes.logo}
          />
          <img
            src="/images/logo/VK-company-logo.svg"
            alt=""
            className={classNames(classes.logo, classes.logoVK)}
          />
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  color: PropTypes.oneOf(['white', 'black']),
  handleFullpageScroll: PropTypes.func,
};

Header.defaultProps = {
  color: 'black',
  handleFullpageScroll: null,
};

export default Header;
