import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

import CMS_QUERY from '../../graphql/cms.query';

const Gql = ({ children, query }) => (
  <>
    {query && query !== true ? (
      <Query query={query}>
        {({ loading, error }) => {
          if (loading) {
            return (
              <Box
                pb={[3, 7, 7]}
                pt={[3, 7, 7]}
                color="error.main"
                justifyContent="center"
                display="flex"
              >
                <CircularProgress color="primary" />
              </Box>
            );
          }

          if (error) {
            return (
              <Box
                pb={[3, 7, 7]}
                pt={[3, 7, 7]}
                color="error.main"
                justifyContent="center"
                display="flex"
              >
                При получении данных произошла ошибка. Попробуйте перезагрузить
                страницу
              </Box>
            );
          }

          return <div>{children}</div>;
        }}
      </Query>
    ) : (
      <div>{children}</div>
    )}
  </>
);

Gql.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  query: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

Gql.defaultProps = {
  query: CMS_QUERY,
};

export default Gql;
