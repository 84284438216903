const styles = theme => ({
  navigation: {
    padding: 0,
    paddingTop: 40,
    paddingBottom: 30,
    listStyleType: 'none',
    fontWeight: 600,
    margin: 0,
  },
  subNavigation: {
    listStyleType: 'none',
    paddingLeft: 5,
    fontWeight: 400,
  },
  item: {
    paddingTop: 40,
    '&:first-child': {
      paddingTop: 0,
    },
  },
  logo: {
    height: '48px',
    marginRight: '20px',
  },
  navigationLink: {
    color: theme.palette.text.primary,
    fontSize: 14,
    lineHeight: 1,
    textDecoration: 'none',
  },
  icon: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  iconButton: {
    color: theme.palette.text.primaryContrast,
    padding: 0,
  },
  iconButton_color_black: {
    color: theme.palette.text.primary,
  },
  copyright: {
    color: '#96969e',
  },
  link: {
    color: theme.palette.text.primary,
    textDecoration: 'underline',
    fontWeight: 600,
    '&:hover': {
      textDecoration: 'none',
    },
  },
  logoVK: {
    height: '16px',
    paddingLeft: '20px',
    borderLeft: '2px solid #AAAEB3',
  },
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
});

export default styles;
