import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { scroller } from 'react-scroll';
import classNames from 'classnames';
import { withRouter } from 'next/router';
import Link from 'next/link';

import ClosedIcon from '@material-ui/icons/ChevronRight';
import OpenedIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';

import NavigationButton from './NavigationButton/NavigationButton';

import Download from '../../Download';
import Social from '../../Social';

import styles from './styles';
import handleScroll from '../../../../helpers/scrollToAnchor';

const SCROLL_DURATION = 800;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const isUrlMatching = (path, href) => {
  if (href === '/') {
    return path === href;
  }
  return path.startsWith(href);
};

class Navigation extends Component {
  state = {
    isExpanded: false,
  };

  handleOpen = href => e => {
    e.preventDefault();

    this.setState(state => ({ [href]: !state[[href]] }));
  };

  handleClick = e => {
    e.preventDefault();
    e.persist();

    const { router } = this.props;

    const { pathname, hash } = new URL(e.target.href);

    if (router.pathname === pathname) {
      if (hash) {
        scroller.scrollTo(hash.substr(1), {
          duration: SCROLL_DURATION,
          smooth: true,
        });
      }
    } else {
      router.push(pathname).then(() => {
        if (hash) {
          scroller.scrollTo(hash.substr(1), {
            duration: SCROLL_DURATION,
            smooth: true,
          });
        }
      });
    }
  };

  handleClose = () => {
    if (this.state.isExpanded) {
      this.setState({ isExpanded: false });
    }
  };

  render() {
    const {
      level,
      color,
      items,
      classes,
      router,
      handleFullpageScroll,
    } = this.props;

    return (
      <div>
        {!level && (
          <IconButton
            className={classNames(
              classes.iconButton,
              color === 'black' && classes.iconButton_color_black
            )}
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={() => this.setState({ isExpanded: true })}
          >
            <img
              src="/images/icons/burger-menu.svg"
              alt=""
              width={24}
              height={24}
            />
          </IconButton>
        )}
        <Dialog
          fullScreen
          open={this.state.isExpanded}
          onClose={this.handleClose}
          disableEnforceFocus
          TransitionComponent={Transition}
        >
          <Box px={2} pt={0} pb={3}>
            <Box py={3} className={classes.icon}>
              <IconButton
                className={classNames(
                  classes.iconButton,
                  classes.iconButton_color_black
                )}
                onClick={this.handleClose}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Box pt={2} className={classes.logoWrapper}>
              <img
                src="/images/logo/payday-logo-black.svg"
                alt="Payday"
                className={classes.logo}
              />
              <img
                src="/images/logo/VK-company-logo.svg"
                alt="VK"
                className={classNames(classes.logo, classes.logoVK)}
              />
            </Box>
            <ul
              className={
                level
                  ? classNames(classes.subNavigation, 'qaSubNavigation')
                  : classNames(classes.navigation, 'qaNavigationHeader')
              }
            >
              {items.map(({ label, pathname, hash, children }) => {
                const path =
                  pathname instanceof Function
                    ? pathname(router.pathname)
                    : pathname;
                const search = '';
                const href = path + (search || '') + (hash || '');

                return (
                  <li
                    className={classNames(classes.item, 'qaNavigationItem')}
                    key={href}
                  >
                    {!!children && !!children.length ? (
                      <Link href={href} passHref scroll={false}>
                        <NavigationButton
                          onCustomClick={this.handleOpen(href)}
                          color={color}
                          level={level}
                        >
                          {label}
                          {!!children &&
                            !!children.length &&
                            (this.state[href] ? (
                              <OpenedIcon />
                            ) : (
                              <ClosedIcon />
                            ))}
                        </NavigationButton>
                      </Link>
                    ) : (
                      <Link href={href} passHref scroll={false}>
                        <NavigationButton
                          onCustomClick={this.handleClick}
                          color={color}
                          level={level}
                          isUrlMatching={isUrlMatching(router.pathname, path)}
                        >
                          {label}
                        </NavigationButton>
                      </Link>
                    )}
                    {!!children && !!children.length && (
                      <Navigation
                        router={router}
                        classes={classes}
                        expanded={!!this.state[href]}
                        items={children}
                        level={level + 1}
                      />
                    )}
                  </li>
                );
              })}
            </ul>
            <Box pt={3} pb={5}>
              <Button
                color="primary"
                variant="contained"
                size="small"
                className={classes.button}
                onClick={() => {
                  this.handleClose();
                  if (typeof handleFullpageScroll === 'function') {
                    handleFullpageScroll();
                  } else {
                    handleScroll('form-anchor');
                  }
                }}
              >
                Узнать подробнее
              </Button>
            </Box>
            <Box>
              <Download title="" size="s" />
            </Box>
            <Box pb={3}>
              <Social />
            </Box>
            <Box pb={3}>
              <a
                href="mailto:support@payday.ru"
                className={classes.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                support@payday.ru
              </a>
            </Box>
            <Box className={classes.copyright}>
              <Box mb={1}>Не является публичной офертой</Box>
              &copy; 2021 VK
            </Box>
          </Box>
        </Dialog>
      </div>
    );
  }
}

Navigation.propTypes = {
  items: PropTypes.array,
  level: PropTypes.number,
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  expanded: PropTypes.bool,
  color: PropTypes.oneOf(['white', 'black']),
  handleFullpageScroll: PropTypes.func,
};

Navigation.defaultProps = {
  items: null,
  level: 0,
  expanded: false,
  color: 'black',
  handleFullpageScroll: null,
};

export default withRouter(withStyles(styles)(Navigation));
