import { createMuiTheme } from '@material-ui/core/styles';

import fonts from './fonts';

export const breakpointsValues = {
  s: 0,
  m: 768,
  l: 1190,
  customCalculator: 325,
  customDocs: 325,
  customSteps: 980,
  customButtons: 400,
  customProfits: 1100,
  customHeaderM: 780,
  customHeaderL: 1440,
  customHeaderXl: 1920,
  customNews: 900,
  customReviews: 1000,
  fullPageOn: 1024,
};

const theme = createMuiTheme({
  typography: {
    fontFamily: ['Montserrat', 'Arial', 'sans-serif'].join(','),
  },
  breakpoints: {
    keys: ['s', 'm', 'l', 'xs'],
    up: key => `@media (min-width:${breakpointsValues[key]}px)`,
    down: key => `@media (max-width:${breakpointsValues[key]}px)`,
  },
  spacing: 10,
  palette: {
    white: '#fff',
    primary: {
      main: '#115cef',
      dark: '#004adb',
      contrastText: '#fff',
    },
    secondary: {
      main: '#fff',
      contrastText: '#242424',
    },
    background: {
      paper: '#f5f6f6',
      dark: '#1c1c1e',
      darkLight: '#38383d',
      default: '#fff',
      accent: '#115cef',
    },
    app: {
      separator: '#e2e2ea',
      header: {
        user: {
          background: '#80d1e6',
          accent: '#242424',
        },
        client: {
          background: '#fff',
          accent: '#f1f1ff',
        },
      },
      slider: {
        rail: '#e0e0ea',
      },
      navigationButtons: {
        default: '#b7bdc6',
        active: '#242424',
      },
    },
    divider: '#e2e6eb',
    success: {
      main: '#fff',
    },
    error: {
      main: '#f94439',
    },
    text: {
      primary: '#090909',
      primaryContrast: '#fff',
      secondary: '#76767b',
      tertiary: '#a3a7ad',
      accent: '#0242c0',
      disabled: '',
      hint: '',
    },
  },
  props: {},
  overrides: {
    MuiTypography: {
      root: {
        letterSpacing: '0.25px',
      },
      h1: {
        fontWeight: 'bold',
        fontSize: '60px',
        lineHeight: 1.2,
        '@media (min-width: 768px)': {
          fontSize: '144px',
          lineHeight: 1.3,
        },
      },
      h2: {
        fontWeight: 'bold',
        fontSize: '60px',
        lineHeight: 1.2,
        '@media (min-width: 768px)': {
          fontSize: '112px',
          lineHeight: 1.3,
        },
      },
      h3: {
        fontWeight: 700,
        fontSize: '24px',
        lineHeight: 1.3,
        letterSpacing: '0.18px',
        '@media (min-width: 768px)': {
          fontSize: '96px',
          lineHeight: '112px',
          letterSpacing: '-1.5px',
        },
      },
      h4: {
        fontWeight: 'bold',
        fontSize: '24px',
        lineHeight: 1.3,
        '@media (min-width: 768px)': {
          fontSize: '60px',
          lineHeight: 1.2,
        },
      },
      h5: {
        fontWeight: 'bold',
        fontSize: '24px',
        lineHeight: 1.3,
      },
      body1: {
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: 1.5,
      },
      body2: {
        fontSize: '14px',
        lineHeight: '20px',
      },
      subtitle1: {
        fontSize: '16px',
        lineHeight: '24px',
        '@media (min-width: 768px)': {
          fontSize: '20px',
          lineHeight: '32px',
        },
      },
      subtitle2: {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: 1.5,
      },
      caption: {
        textTransform: 'uppercase',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#242424',
        display: 'block',
        '@media (min-width: 768px)': {
          fontSize: '20px',
          lineHeight: '30px',
        },
      },
    },
    MuiCard: {
      root: {
        boxShadow: 'none',
      },
    },
    MuiButton: {
      contained: {
        fontSize: '16px !important',
        textTransform: 'none',
        lineHeight: '56px',
        height: '56px',
        padding: '0 52px',
        boxShadow: 'none',
        fontWeight: 700,
        borderRadius: '16px',
        '&:hover, &:active': {
          boxShadow: 'none',
        },
        '&:disabled': {
          background: '#38383d !important',
          color: '#dcdcdc !important',
        },
      },
      containedSizeSmall: {
        fontSize: '14px !important',
        lineHeight: '48px',
        height: '48px',
        padding: '0 16px',
        fontWeight: 600,
      },
      containedSizeLarge: {
        lineHeight: '72px',
        height: '72px',
        padding: '0 105px',
      },
      label: {
        whiteSpace: 'nowrap',
      },
    },
    MuiContainer: {
      root: {
        paddingLeft: '20px',
        paddingRight: '20px',
        '@media (min-width: 768px)': {
          paddingLeft: '40px',
          paddingRight: '40px',
        },
        '@media (min-width: 1190px)': {
          maxWidth: '1190px',
        },
      },
    },
    MuiFormHelperText: {
      root: {
        position: 'absolute',
        top: '100%',
      },
      contained: {
        marginLeft: 0,
        marginTop: '8px',
        fontSize: '14px',
      },
    },
    MuiInputBase: {
      root: {
        background: 'none',
        borderRadius: '16px !important',
        color: '#fff',
        height: '72px',
        borderColor: '#38383d',
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: '#38383d',
      },
      input: {
        paddingLeft: '24px',
        paddingRight: '24px',
        textFillColor: 'white !important',
        boxShadow: '0 0 0 30px #1C1C1E inset !important',
        WebkitBackgroundClip: 'text',
        backgroundClip: 'text',
      },
      root: {
        '&.Mui-focused fieldset': {
          borderColor: '#fff !important',
          borderWidth: '1px !important',
        },
        '&:hover fieldset': {
          borderColor: '#fff !important',
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: '#fff',
        fontSize: '14px',
        textTransform: 'none !important',
        marginBottom: '24px !important',
        transform: 'translate(0, 1.5px) scale(1) !important',
      },
    },
    MuiCssBaseline: {
      '@global': {
        '@font-face': fonts,
        a: {
          color: '#fff',
          '&:hover': {
            textDecoration: 'none',
          },
        },
      },
    },
  },
});

export default theme;
