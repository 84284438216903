import { scroller } from 'react-scroll';

const SCROLL_DURATION = 800;

const handleScroll = anchorName => {
  scroller.scrollTo(anchorName, {
    duration: SCROLL_DURATION,
    smooth: true,
  });
};

export default handleScroll;
