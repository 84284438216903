import MontserratRegular from './Montserrat-Regular.woff2';
import MontserratMedium from './Montserrat-Medium.woff2';
import MontserratSemiBold from './Montserrat-SemiBold.woff2';
import MontserratBold from './Montserrat-Bold.woff2';
import MontserratBlack from './Montserrat-Black.woff2';
import VKSansDisplayRegular from './VK-Sans-Display-Regular.woff2';

const fonts = [
  {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 400,
    src: `
      local('Montserrat Regular'),
      local('Montserrat-Regular'),
      url(${MontserratRegular}) format('woff2')
    `,
    unicodeRange: 'U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116',
  },
  {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 400,
    src: `
      local('Montserrat Regular'),
      local('Montserrat-Regular'),
      url(${MontserratRegular}) format('woff2')
    `,
    unicodeRange:
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD',
  },
  {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 500,
    src: `
      local('Montserrat Medium'),
      local('Montserrat-Medium'),
      url(${MontserratMedium}) format('woff2')
    `,
    unicodeRange: 'U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116',
  },
  {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 500,
    src: `
      local('Montserrat Medium'),
      local('Montserrat-Medium'),
      url(${MontserratMedium}) format('woff2')
    `,
    unicodeRange:
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD',
  },
  {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 600,
    src: `
      local('Montserrat SemiBold'),
      local('Montserrat-SemiBold'),
      url(${MontserratSemiBold}) format('woff2')
    `,
    unicodeRange: 'U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116',
  },
  {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 600,
    src: `
      local('Montserrat SemiBold'),
      local('Montserrat-SemiBold'),
      url(${MontserratSemiBold}) format('woff2')
    `,
    unicodeRange:
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD',
  },
  {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 700,
    src: `
      local('Montserrat Bold'),
      local('Montserrat-Bold'),
      url(${MontserratBold}) format('woff2')
    `,
    unicodeRange: 'U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116',
  },
  {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 700,
    src: `
      local('Montserrat Bold'),
      local('Montserrat-Bold'),
      url(${MontserratBold}) format('woff2')
    `,
    unicodeRange:
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD',
  },
  {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 900,
    src: `
      local('Montserrat Black'),
      local('Montserrat-Black'),
      url(${MontserratBlack}) format('woff2')
    `,
    unicodeRange: 'U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116',
  },
  {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 900,
    src: `
      local('Montserrat Black'),
      local('Montserrat-Black'),
      url(${MontserratBlack}) format('woff2')
    `,
    unicodeRange:
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD',
  },
  {
    fontFamily: 'VKSans',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 400,
    src: `
      local('VKSans Regular'),
      local('VKSans-Regular'),
      url(${VKSansDisplayRegular}) format('woff2')
    `,
    unicodeRange: 'U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116',
  },
  {
    fontFamily: 'VKSans',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 400,
    src: `
      local('VKSans Regular'),
      local('VKSans-Regular'),
      url(${VKSansDisplayRegular}) format('woff2')
    `,
    unicodeRange:
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD',
  },
];

export default fonts;
