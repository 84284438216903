import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  navigationLink: {
    display: 'inline-flex',
    alignItems: 'center',
    color: theme.palette.text.primaryContrast,
    fontSize: 14,
    lineHeight: '32px',
    height: '32px',
    padding: '0 8px',
    fontWeight: 600,
    borderRadius: '10px',
    textDecoration: 'none',
    marginRight: 32,
    transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      background: 'rgba(255, 255, 255, .5)',
    },
  },
  navigationLink_color_black: {
    color: theme.palette.text.primary,
  },
  navigationLink_active_yes: {
    color: theme.palette.primary.main,
    cursor: 'default',
    '&:hover': {
      background: 'transparent',
    },
  },
  icon: {
    fontSize: 24,
    lineHeight: 28,
  },
  navigationLink_theme_dark: {
    '&:hover': {
      background: theme.palette.background.paper,
    },
  },
}));

export default useStyles;
